import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _PageTitle(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <h1 className="page-title">{props.children}</h1>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_PageTitle.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.any,
}

_PageTitle.defaultProps = {}

// ----------------------------------------------------------------------------

const PageTitle = styled(_PageTitle)`
    & {
        h1.page-title {
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            h1 {
                font-size: 22px;
                font-weight: 700;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default PageTitle
