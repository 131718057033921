import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"
import { message } from "antd"

import * as api from "../../../api"

import { useStateValue } from "../../../_shared/context/AppStateStore"

import useRestResource from "../../../_shared/hooks/useRestResource"
import PageTitle from "../../../_shared/components/PageTitle"
// import Page from "../../../_shared/components/PageTitle"
import Chart from "./Chart"
import ChartActions from "./ChartActions"

// ----------------------------------------------------------------------------

const LIVE_MODE_POLLING_INTERVAL = 1000

const MODULES_MAP = [
    {
        key: "weightedMass",
        label: "Weighted Mass",
    },
    {
        key: "air",
        label: "Air",
    },
    {
        key: "water",
        label: "Water",
    },
    {
        key: "energy",
        label: "Energy",
    },
]

const LINE_COLORS = ["#D16D4C", "#B1D3DA", "#83C5D0", "#5993A0", "#9F5D7F"]

// ----------------------------------------------------------------------------

function _ModuleDetail(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    let { id: moduleId } = useParams()

    const [filter, setFilter] = useState("live")
    // const [excludeThreshold, setExcludeThreshold] = useState(null)
    const [{ token }] = useStateValue()

    const [pollingSeconds, setPollingSeconds] = useState(
        LIVE_MODE_POLLING_INTERVAL
    )

    const [components, , , { onFiltersChange: onLogsFiltersChange }] =
        useRestResource(`/plant-logs/modules/${moduleId}`, token, {
            polling: {
                // interval: pollingSeconds,
                getFilters: () => ({
                    from: dayjs().subtract(1, "minute").toISOString(),
                    to: dayjs().toISOString(),
                }),
            },
            defaultFilters: {
                from: dayjs().subtract(1, "minute").toISOString(),
                to: dayjs().toISOString(),
            },
        })

    // const componentsLog = components?.rows?.[0] ?? []

    console.log("componentsLog: ", components)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function getDeviceName(deviceId) {
        const namesMap = {
            "13_1": "SAM",
            "13_2": "MasterS",
            "14_2": "MasterT",
            "13_3": "Mixer",
            "13_4": "Magrob",
        }

        return namesMap[deviceId]
    }

    function getFiltersForMode(mode) {
        if (mode === "live") {
            return {
                from: dayjs().subtract(1, "minute").toISOString(),
                to: dayjs().toISOString(),
            }
        } else {
            if (mode === "today") {
                return {
                    from: dayjs().startOf("day").toISOString(),
                    to: dayjs().endOf("day").toISOString(),
                }
            } else {
                return {
                    from: dayjs().subtract(7, "day").toISOString(),
                    to: dayjs().toISOString(),
                }
            }
        }
    }

    async function handleNoteCreated(note) {
        try {
            await api.createResource("/notes", token, {
                ...note,
                type: "module-detail-" + moduleId,
            })

            message.success("Nota creata con successo")
        } catch (error) {
            console.error(error)
            message.error(
                "Impossibile creare la nota. Si prega riprovare più tardi"
            )
        }
    }

    async function handleSend(data) {
        const { to } = data

        try {
            const filtersToExport = getFiltersForMode(filter)

            await api.sendModuleLogsByEmail(
                moduleId,
                to,
                filtersToExport,
                token
            )

            message.success("Email inviata con successo.")
        } catch (error) {
            console.error(error)
            message.error(
                "Impossibile inviare l'email. Si prega riprovare più tardi"
            )
        }
    }

    async function handleAlarmSet() {
        try {
            await api.createResource("/alarms", token, {
                type: "module-detail-" + moduleId,
            })

            message.success("Allarme creato con successo")
        } catch (error) {
            console.error(error)
            message.error(
                "Impossibile creare l'allarme. Si prega riprovare più tardi"
            )
        }
    }

    function handleThresholdExclude(threshold) {}

    function getComponentsDatasets() {
        return components?.map?.((component, index) => {
            const componentsValue = component?.logs?.reduce?.(
                (acc, log) => acc + log?.[moduleId],
                0
            )

            return {
                label: `Device ${getDeviceName(component.deviceId)}`,
                data: [0, componentsValue, 0] ?? [],
                backgroundColor: LINE_COLORS[index],
            }
        })
    }

    function getPageTitle() {
        const moduleLabel =
            MODULES_MAP.find((m) => m.key === moduleId)?.label ?? ""

        return `Module Impact - ${moduleLabel} Detail Overview`
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const filterOptions = {
        active: filter,
        filters: [
            { label: "Live data", value: "live" },
            { label: "Today", value: "today" },
            { label: "Last week", value: "week" },
        ],
        onChange: setFilter,
    }

    const chartData = {
        labels: ["", `Last minute total impact`, ""],
        datasets: getComponentsDatasets() ?? [],
    }

    console.log("getComponentsDatasets: ", chartData)

    const exportURL = `${api.BASE_URL}/plant-logs/modules/${moduleId}/export`

    const exportURLWithQuery = api.urlWithQueryParams(
        exportURL,
        getFiltersForMode(filter)
    )

    return (
        <div className={`${className}`}>
            <div className="left">
                <PageTitle>Consumptions detail</PageTitle>
                <Chart
                    className="chart"
                    data={chartData}
                    title={getPageTitle()}
                    filterParams={filterOptions}
                />
            </div>
            <div className="right">
                <ChartActions
                    className="actions"
                    onNoteCreated={handleNoteCreated}
                    onAlarmSet={handleAlarmSet}
                    exportUrl={exportURLWithQuery}
                    onSend={handleSend}
                    onExclude={handleThresholdExclude}
                />
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_ModuleDetail.propTypes = {
    className: PropTypes.string.isRequired,
}

_ModuleDetail.defaultProps = {}

// ----------------------------------------------------------------------------

const ModuleDetail = styled(_ModuleDetail)`
    & {
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding: 0px ${({ theme }) => theme.spacing.s}px;
        .left {
            flex: 1;
            padding-right: ${({ theme }) => theme.spacing.s}px;
            .page-title {
                margin: 0%;
                padding: 0;
                padding-top: ${({ theme }) => theme.spacing.m}px;
            }
            .chart {
                margin-top: ${({ theme }) => theme.spacing.s}px;
            }
        }
        .right {
        }
    }
`
// ----------------------------------------------------------------------------

export default ModuleDetail
