import React from "react"
import { Redirect, Route, Switch, withRouter } from "react-router-dom"

import { useStateValue } from "../_shared/context/AppStateStore"
import AuthPage from "./AuthPage"
import LoggedApp from "./LoggedApp"

function RoutedApp(props) {
    const [{ user }] = useStateValue()

    const { match, location } = props

    if (user) {
        return (
            <Switch>
                <Redirect from={`${match.url}auth`} to={`${match.url}`} />
                <Route path={`${match.url}`} component={LoggedApp} />
            </Switch>
        )
    } else {
        return (
            <Switch>
                {location.pathname !== "/auth" && (
                    <Redirect from={`${match.url}`} to={`${match.url}auth`} />
                )}
                <Route exact path={`${match.url}auth/`} component={AuthPage} />
            </Switch>
        )
    }
}

// ----------------------------------------------------------------------------
// Router wrapper
// ----------------------------------------------------------------------------

const RoutedAppWithRouter = withRouter(RoutedApp)

// ----------------------------------------------------------------------------

export default RoutedAppWithRouter
