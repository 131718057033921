import PropTypes from "prop-types"
import React from "react"
import { AiOutlinePoweroff } from "react-icons/ai"
import { IoMdDocument as DocumentIcon } from "react-icons/all"
import { IoCodeSlash as CodeIcon } from "react-icons/all"
import { BsFillPersonFill as PersonIcon } from "react-icons/bs"
import { useLocation, useRouteMatch } from "react-router"
import { Link } from "react-router-dom"
import styled from "styled-components"

import Image from "../../../assets/images/logo_cortina_white.png"
import { useStateValue } from "../../../context/AppStateStore"

// ----------------------------------------------------------------------------

function _Menu(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [, dispatch] = useStateValue()

    const location = useLocation()
    const { pathname } = location

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleLogout() {
        return dispatch({
            type: "SIGN_OUT",
        })
    }

    function getIsActive(className, strict) {
        if (strict) return className === pathname && "menu-link active"
        else {
            const pat = new RegExp(className)
            return pat.test(pathname) && "menu-link active"
        }
    }

    function renderLinks() {
        return (
            <div className="links">
                <Link to="/" className={`menu-item ${getIsActive("/", true)}`}>
                    <span className="menu-text">Plant overview</span>
                </Link>
                <Link
                    to="/plant-general"
                    className={`menu-item ${getIsActive(
                        "/plant-general",
                        false
                    )}`}
                >
                    <span className="menu-text">Plant general</span>
                </Link>
                {/* <Link
                    to="/impact-detail"
                    className={`menu-item ${getIsActive(
                        "/impact-detail",
                        false
                    )}`}
                >
                    <span className="menu-text">Impact detail</span>
                </Link> */}
                {/* <Link
                    to="/module-detail"
                    className={`menu-item ${getIsActive(
                        "/module-detail",
                        false
                    )}`}
                >
                    <span className="menu-text">Module detail</span>
                </Link> */}
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <div className="logo">
                {/* <img className="logo-img" src={Image} alt="logo"></img> */}
                <h1 className="title">REEF</h1>
            </div>
            {renderLinks()}

            <div className="logout" onClick={handleLogout}>
                <div className="icon">
                    <AiOutlinePoweroff />
                </div>
                <span className="logout-text">Logout</span>
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Menu.propTypes = {
    className: PropTypes.string.isRequired,
}

_Menu.defaultProps = {}

// ----------------------------------------------------------------------------

const Menu = styled(_Menu)`
    & {
        width: 250px;
        min-width: 250px;
        max-width: 250px;
        height: 100vh;
        padding-top: 30px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: ${({ theme }) => theme.colors.primary};

        .logo {
            /* min-height: 150px; */
            margin-bottom: 50px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .logo-img {
                width: 150px;
                height: 100%;
                object-fit: contain;
            }
        }

        .title {
            color: #ffffff;
            font-size: 45px;
        }

        .links {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            .divider {
                margin: 10px 0px;
                width: 90%;
                height: 1px;
                background: rgb(31, 40, 58);
                box-shadow: rgba(255, 255, 255, 0.05) 0px 1px 0px;
                border-radius: 30px;
            }
            .menu-item {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80%;
                color: ${(props) => props.theme.colors.light};
                transition: all 200ms ease;
                margin-bottom: ${({ theme }) => theme.spacing.m}px;

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    * {
                        color: ${(props) => props.theme.colors.white};
                        width: 18px;
                        height: 18px;
                    }
                }
                .menu-text {
                    color: ${(props) => props.theme.colors.white};
                    font-weight: normal;
                    display: block;
                    font-size: 16px;
                    font-weight: 700;
                }

                &.active {
                    position: relative;
                    padding-left: 0;

                    height: 40px;

                    overflow: visible;
                    background-color: ${(props) =>
                        props.theme.colors.menuActive};

                    border-radius: 4px;
                    border-left: 5px solid
                        ${(props) => props.theme.colors.secondary};

                    .menu-text {
                        font-weight: 700;
                        color: ${(props) => props.theme.colors.dark} !important;
                    }
                    .icon {
                        * {
                            color: ${(props) => props.theme.colors.light};
                        }
                    }
                }
            }
        }

        .logout {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .icon {
                display: block;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    fill: ${({ theme }) => theme.colors.light};
                    width: 16px;
                    height: 16px;
                }
            }
            .logout-text {
                display: block;
                color: ${({ theme }) => theme.colors.light};
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Menu
