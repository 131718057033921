import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _Button(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, icon, children, onClick } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <button onClick={onClick} type="button" className={`${className}`}>
            <div className="button-inner">
                <div className="icon">{icon}</div>
                <div className="text">{children}</div>
            </div>
        </button>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Button.propTypes = {
    className: PropTypes.string.isRequired,
    icon: PropTypes.object,
    children: PropTypes.any,
    onClick: PropTypes.func,
}

_Button.defaultProps = {}

// ----------------------------------------------------------------------------

const Button = styled(_Button)`
    & {
        /* reset defaults */
        border: none;
        outline: none;
        background: none;

        /* styling */
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 100%;
        border: 1px solid ${({ theme }) => theme.colors.dark300};
        border-radius: 10px;
        font-size: 16px;
        cursor: pointer;
        transition: box-shadow 200ms ease;

        &:focus {
            box-shadow: 0px 0px 6px 2px
                ${({ theme }) => theme.colors.primaryShadow};
        }

        .button-inner {
            width: 140px;
            display: flex;
        }

        .text {
            text-align: left;
        }

        .icon {
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Button
