import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import styled from "styled-components"

import * as api from "../../api"

import { useStateValue } from "../../_shared/context/AppStateStore"
import Layout from "../../_shared/components/Layout"

// import ImpactDetail from "./ImpactDetail"
import ModuleDetail from "./ModuleDetail"
import PlantGeneral from "./PlantGeneral"
import PlantOverview from "./PlantOverview"
import DeviceDetail from "./DeviceDetail"

// ----------------------------------------------------------------------------

function _LoggedApp(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }, dispatch] = useStateValue()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        // api.getMe(token).catch(() => {
        //     dispatch({
        //         type: "SIGN_OUT",
        //     })
        // })
        //eslint-disable-next-line
    }, [])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Layout className={`${className}`}>
            <Switch>
                <Route path="/" exact component={PlantOverview} />
                <Route path="/plant-general" exact component={PlantGeneral} />
                {/* <Route path="/impact-detail" exact component={ImpactDetail} /> */}
                <Route
                    path="/consumptions/:id"
                    exact
                    component={ModuleDetail}
                />

                <Route path="/modules/:id" exact component={DeviceDetail} />
            </Switch>
        </Layout>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_LoggedApp.propTypes = {
    className: PropTypes.string.isRequired,
}

_LoggedApp.defaultProps = {}

// ----------------------------------------------------------------------------

const LoggedApp = styled(_LoggedApp)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default LoggedApp
