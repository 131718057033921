import { message } from "antd"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import * as api from "../../../api"

import { useStateValue } from "../../../_shared/context/AppStateStore"

import PageTitle from "../../../_shared/components/PageTitle"
import useRestResource from "../../../_shared/hooks/useRestResource"
import Chart from "./Chart"
import ChartActions from "./ChartActions"

// ----------------------------------------------------------------------------

const LIVE_MODE_POLLING_INTERVAL = 1000

const DEVICE_NAMES = {
    "13_1": "SAM",
    "13_2": "MasterS",
    "14_2": "MasterT",
    "13_3": "Mixer",
    "13_4": "Magrob",
}

// ----------------------------------------------------------------------------

function _PlantGeneral(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const { id: deviceId } = useParams()

    const [{ token }] = useStateValue()

    const [pollingSeconds, setPollingSeconds] = useState(
        LIVE_MODE_POLLING_INTERVAL
    )

    const [logs, , , { onFiltersChange: onLogsFiltersChange }] =
        useRestResource("/plant-logs", token, {
            polling: {
                interval: pollingSeconds,
                getFilters: () => ({
                    from: dayjs().subtract(1, "minute").toISOString(),
                    to: dayjs().toISOString(),
                }),
            },
            defaultFilters: {
                from: dayjs().subtract(1, "minute").toISOString(),
                to: dayjs().toISOString(),
                device: deviceId,
            },
        })

    const [filter, setFilter] = useState("live")

    const [datas, setDatas] = useState()

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (filter === "live") {
            setPollingSeconds(LIVE_MODE_POLLING_INTERVAL)
        } else {
            setPollingSeconds(null)
            if (filter === "today") {
                onLogsFiltersChange({
                    from: dayjs().startOf("day").toISOString(),
                    to: dayjs().endOf("day").toISOString(),
                    device: deviceId,
                })
            } else {
                if (filter === "week") {
                    onLogsFiltersChange({
                        from: dayjs().subtract(7, "day").toISOString(),
                        to: dayjs().toISOString(),
                        device: deviceId,
                    })
                } else {
                    console.log("custom filter ", datas)
                }
            }
        }
        //eslint-disable-next-line
    }, [filter, deviceId])

    useEffect(() => {
        datas &&
            datas[0] !== "" &&
            datas[1] !== "" &&
            onLogsFiltersChange({
                from: dayjs(datas?.[0]).toISOString(),
                to: dayjs(datas?.[1]).toISOString(),
                device: deviceId,
            })
        //eslint-disable-next-line
    }, [datas])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function getFiltersForMode(mode) {
        if (mode === "live") {
            return {
                from: dayjs().subtract(1, "minute").toISOString(),
                to: dayjs().toISOString(),
                device: deviceId,
            }
        } else {
            if (mode === "today") {
                return {
                    from: dayjs().startOf("day").toISOString(),
                    to: dayjs().endOf("day").toISOString(),
                    device: deviceId,
                }
            } else {
                if (mode === "week") {
                    return {
                        from: dayjs().subtract(7, "day").toISOString(),
                        to: dayjs().toISOString(),
                        device: deviceId,
                    }
                } else {
                    return {
                        from:
                            datas && datas[0] && dayjs(datas[0]).toISOString(),
                        to: datas && datas[1] && dayjs(datas[1]).toISOString(),
                        device: deviceId,
                    }
                }
            }
        }
    }

    function getChartData(key, multiplier) {
        return logs?.rows?.map((log) => {
            if (key === "total") {
                return (
                    log["energy"] * 1.26652e-7 +
                    log["water"] * 0.000426605 +
                    log["air"] * 0.0000692 +
                    log["weightedMass"] * 4.9155664
                )
            }

            return log[key] * multiplier
        })
    }

    function getXLabels() {
        return logs?.rows?.map((log) => dayjs(log.createdAt).format("HH:mm"))
    }

    async function handleNoteCreated(note) {
        try {
            await api.createResource("/notes", token, {
                ...note,
                type: "plant-general",
            })

            message.success("Nota creata con successo")
        } catch (error) {
            console.error(error)
            message.error(
                "Impossibile creare la nota. Si prega riprovare più tardi"
            )
        }
    }

    async function handleSend(data) {
        const { to } = data

        try {
            const filtersToExport = getFiltersForMode(filter)

            await api.sendLogsByEmail(to, filtersToExport, token)

            message.success("Email inviata con successo.")
        } catch (error) {
            console.error(error)
            message.error(
                "Impossibile inviare l'email. Si prega riprovare più tardi"
            )
        }
    }

    async function handleAlarmSet() {
        try {
            await api.createResource("/alarms", token, {
                type: "plant-general",
            })

            message.success("Allarme creato con successo")
        } catch (error) {
            console.error(error)
            message.error(
                "Impossibile creare l'allarme. Si prega riprovare più tardi"
            )
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const filterOptions = {
        active: filter,
        filters: [
            { label: "Live data", value: "live" },
            { label: "Today", value: "today" },
            { label: "Last week", value: "week" },
            { label: "Custom", value: "custom" },
        ],
        onChange: setFilter,
    }

    const chartData = {
        labels: getXLabels(),
        datasets: [
            {
                key: "Cumulata",
                label: "Cumulata",
                data: getChartData("total", 1),
                fill: false,
                backgroundColor: "#D16D4C",
                borderColor: "#D16D4C",
            },
            {
                key: "Spreco polveri",
                label: "Spreco polveri",
                data: getChartData("weightedMass", 4.9155664),
                fill: false,
                backgroundColor: "#9F5D7F",
                borderColor: "#9F5D7F",
            },
            {
                key: "Aria compressa",
                label: "Aria compressa",
                data: getChartData("air", 0.0000692),
                fill: false,
                backgroundColor: "#5993A0",
                borderColor: "#5993A0",
            },
            {
                key: "H2O",
                label: "H2O",
                data: getChartData("water", 0.000426605),
                fill: false,
                backgroundColor: "#F7E291",
                borderColor: "#F7E291",
            },
            {
                key: "E",
                label: "E",
                data: getChartData("energy", 1.26652e-7),
                fill: false,
                backgroundColor: "#B1D3DA",
                borderColor: "#B1D3DA",
            },
        ],
    }

    const exportURL = `${api.BASE_URL}/plant-logs/export`

    const exportURLWithQuery = api.urlWithQueryParams(
        exportURL,
        getFiltersForMode(filter)
    )

    return (
        <div className={`${className}`}>
            <div className="left">
                <PageTitle>Module detail</PageTitle>
                <Chart
                    className="chart"
                    data={chartData}
                    title={`${DEVICE_NAMES[deviceId]} Impact`}
                    filterParams={filterOptions}
                    mode={filter}
                    showDates={filter === "custom"}
                    setDatas={setDatas}
                />
            </div>
            <div className="right">
                <ChartActions
                    onNoteCreated={handleNoteCreated}
                    onAlarmSet={handleAlarmSet}
                    exportUrl={exportURLWithQuery}
                    onSend={handleSend}
                    className="actions"
                />
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_PlantGeneral.propTypes = {
    className: PropTypes.string.isRequired,
}

_PlantGeneral.defaultProps = {}

// ----------------------------------------------------------------------------

const PlantGeneral = styled(_PlantGeneral)`
    & {
        display: flex;
        align-items: flex-start;
        padding: 0px ${({ theme }) => theme.spacing.s}px;
        .left {
            flex: 1;
            padding-right: ${({ theme }) => theme.spacing.s}px;
            .page-title {
                margin: 0%;
                padding: 0;
                padding-top: ${({ theme }) => theme.spacing.m}px;
            }
            .chart {
                margin-top: ${({ theme }) => theme.spacing.s}px;
            }
        }
        .right {
        }
    }
`
// ----------------------------------------------------------------------------

export default PlantGeneral
