import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { BsChevronDown } from "react-icons/bs"
import styled from "styled-components"
import { Line } from "react-chartjs-2"

import CustomSelect from "../../../../_shared/components/FormItems/CustomSelect"
import { DatePicker, Space } from "antd"
const { RangePicker } = DatePicker

// ----------------------------------------------------------------------------

// "13_1": "SAM",
// "13_2": "MasterS",
// "14_2": "MasterT",
// "13_3": "Mixer",
// "13_4": "Magrob",

const MODULES = [
    {
        key: "13_1",
        label: "SAM",
    },
    {
        key: "13_2",
        label: "MasterS",
    },
    {
        key: "13_3",
        label: "Mixer",
    },
    {
        key: "13_4",
        label: "Magrob",
    },
    {
        key: "14_2",
        label: "MasterT",
    },
]

const CONSUMPTIONS = [
    {
        key: "weightedMass",
        label: "Spreco polveri",
    },
    {
        key: "air",
        label: "Aria",
    },
    {
        key: "water",
        label: "Acqua",
    },
    {
        key: "energy",
        label: "energia",
    },
]

// ----------------------------------------------------------------------------
function _Chart(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        data,
        title,
        filterParams = { filters: [], active: null, onChange: () => {} },
        mode,
        showDates,
        setDatas,
    } = props

    const { filters, active, onChange } = filterParams

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const history = useHistory()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    const getAxisOptions = ({ title, font }) => ({
        grid: {
            lineWidth: 0,
            drawBorder: false,
        },

        ticks: {
            font: {
                size: 12,
                weight: 600,
            },
        },

        title: {
            display: !!title,
            text: title,
            font: font ?? {
                size: 16,
                weight: 700,
            },
        },
    })

    function renderFilterSelector() {
        return (
            <CustomSelect
                renderSelector={({ label }) => (
                    <div className="filter-select">
                        <span className="select-value">{label}</span>
                        <div className="select-icon">
                            <BsChevronDown />
                        </div>
                    </div>
                )}
                options={filters}
                value={active}
                onChange={onChange}
            />
        )
    }

    function renderModuleSelector() {
        return (
            <CustomSelect
                renderSelector={({ label }) => (
                    <div className="filter-select">
                        <span className="select-value">Modulo</span>
                        <div className="select-icon">
                            <BsChevronDown />
                        </div>
                    </div>
                )}
                options={MODULES?.map(({ key, label }) => ({
                    label: label,
                    value: key,
                }))}
                value={active}
                onChange={(moduleId) => history.push(`/modules/${moduleId}`)}
            />
        )
    }

    function renderConsumptionTypeSelector() {
        return (
            <CustomSelect
                renderSelector={({ label }) => (
                    <div className="filter-select">
                        <span className="select-value">Consumo</span>
                        <div className="select-icon">
                            <BsChevronDown />
                        </div>
                    </div>
                )}
                options={CONSUMPTIONS?.map(({ key, label }) => ({
                    label: label,
                    value: key,
                }))}
                value={active}
                onChange={(moduleId) =>
                    history.push(`/consumptions/${moduleId}`)
                }
            />
        )
    }

    function renderDates() {
        return (
            <div>
                <RangePicker
                    style={{ height: "40px" }}
                    onChange={(value, dateStrings) => setDatas(dateStrings)}
                />
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <h3 className="chart-title">{title}</h3>
            <div className="selector-wrapper">
                {showDates && renderDates()}
                {renderFilterSelector()}
                {renderConsumptionTypeSelector()}
                {renderModuleSelector()}
            </div>{" "}
            <Line
                data={data}
                options={{
                    animation: {
                        duration: 0,
                    },
                    datasets: {
                        scales: {
                            y: {
                                beginAtZero: true,
                            },
                        },
                    },
                    scales: {
                        y: getAxisOptions({ title: "kgCO2eq" }),
                        x: getAxisOptions({
                            title: mode === "live" ? "Refresh time: 1 sec" : "",
                            font: {
                                size: 14,
                                weight: 400,
                            },
                        }),
                    },
                }}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Chart.propTypes = {
    className: PropTypes.string.isRequired,
    data: PropTypes.object,
    options: PropTypes.object,
    title: PropTypes.string,
    mode: PropTypes.string,
    filterParams: PropTypes.object,
}

_Chart.defaultProps = {}

// ----------------------------------------------------------------------------

const Chart = styled(_Chart)`
    & {
        flex: 1;
        padding: ${({ theme }) => theme.spacing.s}px;
        box-shadow: 0px 0px 12px ${({ theme }) => theme.colors.shadow};

        .chart-title {
            text-align: center;
            display: block;
            font-size: 16px;
            margin: 0;
            padding: 0;
            padding-bottom: ${({ theme }) => theme.spacing.s}px;
            font-size: 16px;
            font-weight: 600;
        }
        .selector-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            margin-bottom: ${({ theme }) => theme.spacing.s}px;

            .filter-select {
                width: 120px;
                height: 40px;
                border-radius: 6px;
                background-color: ${({ theme }) => theme.colors.dark100};
                display: flex;
                align-self: center;
                justify-content: center;
                padding: 0px 10px;

                .select-value {
                    display: block;
                    min-width: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-weight: 500;
                }

                .select-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: ${({ theme }) => theme.spacing.xs}px;
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Chart
