import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import dayjs from "dayjs"

// import * as api from "../../../api"

import { useStateValue } from "../../../_shared/context/AppStateStore"
import useRestResource from "../../../_shared/hooks/useRestResource"

import PageTitle from "../../../_shared/components/PageTitle"
import Chart from "./Chart"

// ----------------------------------------------------------------------------

function _PlantOverview(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [filter, setFilter] = useState("live")
    const [datas, setDatas] = useState()

    const [logs, , , { onFiltersChange: onLogsFiltersChange }] =
        useRestResource("/plant-logs", token, {
            polling: {
                interval: 60000,
                getFilters: () => ({
                    from: dayjs().subtract(1, "minute").toISOString(),
                    to: dayjs().toISOString(),
                    limit: 1,
                }),
            },
            defaultFilters: {
                from: dayjs().subtract(1, "minute").toISOString(),
                to: dayjs().toISOString(),
                limit: 1,
            },
        })

    const currentLog = logs?.rows?.[0] ?? {}

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    useEffect(() => {
        datas &&
            datas[0] !== "" &&
            datas[1] !== "" &&
            onLogsFiltersChange({
                from: dayjs(datas?.[0]).toISOString(),
                to: dayjs(datas?.[1]).toISOString(),
            })
        //eslint-disable-next-line
    }, [datas])

    const filterOptions = {
        active: filter,
        filters: [
            { label: "Live data", value: "live" },
            { label: "Custom", value: "custom" },
        ],
        onChange: setFilter,
    }

    function getChartData() {
        return [
            currentLog["energy"],
            currentLog["water"],
            currentLog["weightedMass"],
            currentLog["air"],
        ]
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const chartData = getChartData()

    console.log("CHART DATA: ", chartData)

    return (
        <div className={className}>
            <PageTitle>Plant overview</PageTitle>
            <Chart
                className="chart"
                data={chartData}
                filterParams={filterOptions}
                showDates={filter === "custom"}
                setDatas={setDatas}
            ></Chart>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_PlantOverview.propTypes = {
    className: PropTypes.string.isRequired,
}

_PlantOverview.defaultProps = {}

// ----------------------------------------------------------------------------

const PlantOverview = styled(_PlantOverview)`
    & {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px ${({ theme }) => theme.spacing.s}px;
        .page-title {
            margin: 0%;
            padding: 0;
            padding-top: ${({ theme }) => theme.spacing.m}px;
        }

        .chart {
            margin-top: ${({ theme }) => theme.spacing.s}px;
        }
    }
`
// ----------------------------------------------------------------------------

export default PlantOverview
