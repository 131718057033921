import PropTypes from "prop-types"
import React, { useState, useLayoutEffect, useRef } from "react"
import styled from "styled-components"
import { message } from "antd"

import { ReactComponent as Alarm } from "../../../../_shared/assets/icons/Chart/alarm.svg"
import { ReactComponent as Export } from "../../../../_shared/assets/icons/Chart/export.svg"
import { ReactComponent as Send } from "../../../../_shared/assets/icons/Chart/send.svg"
import AppButton from "../../../../_shared/components/AppButton/index"
import AppInput from "../../../../_shared/components/FormItems/AppInput/index.js"
import AppTextArea from "../../../../_shared/components/FormItems/AppTextarea/index.js"
import CheckboxGroup from "../../../../_shared/components/FormItems/CheckboxGroup/index.js"
import Checkbox from "../../../../_shared/components/FormItems/Checkbox/index.js"

import Button from "./Button"

// ----------------------------------------------------------------------------

function _ChartActions(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        onNoteCreated,
        onAlarmSet,
        onExport,
        onSend,
        onExclude,
        exportUrl,
    } = props

    const [sendFormVisible, setSendToFormVisible] = useState(false)
    const [email, setEmail] = useState(false)

    const [addNoteVisible, setAddNoteVisibile] = useState(false)
    const [note, setNote] = useState("")

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleNoteCreated(note) {
        onNoteCreated &&
            onNoteCreated(note).then(() => setSendToFormVisible(false))
    }

    function handleSend() {
        onSend &&
            onSend({ to: email }).then(() => {
                setSendToFormVisible(false)
                setEmail("")
            })
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <h1 className="heading">Actions</h1>
            <Button
                htmlType="submit"
                uxType="secondary"
                className="button"
                onClick={() => {
                    setSendToFormVisible(!sendFormVisible)
                }}
                icon={<Send />}
            >
                Send to
            </Button>
            {sendFormVisible && (
                <>
                    <AppInput
                        value={email}
                        onChange={setEmail}
                        label="Indirizzo email"
                        className="input"
                    />
                    <AppButton
                        htmlType="submit"
                        uxType="primary"
                        className="button-send"
                        onClick={handleSend}
                    >
                        Send
                    </AppButton>
                </>
            )}
            <a style={{ color: "black" }} href={exportUrl} download="logs.csv">
                <Button
                    icon={<Export />}
                    htmlType="submit"
                    uxType="secondary"
                    className="button"
                >
                    Export Data
                </Button>
            </a>
            <Button
                icon={<Alarm />}
                htmlType="submit"
                uxType="secondary"
                className="button"
                onClick={onAlarmSet}
            >
                Set Alarm
            </Button>
            <h2 className="heading">Notes</h2>
            <AppButton
                htmlType="submit"
                uxType="primary"
                className="button-send"
                onClick={() => {
                    setAddNoteVisibile(!addNoteVisible)
                }}
            >
                Inserisci nuova nota
            </AppButton>
            <CreateNote
                visible={addNoteVisible}
                onConfirm={handleNoteCreated}
            />
            {/* <h2 className="heading">Rules</h2> */}
            {/* <CheckboxGroup options={["Only Total", "Exluce"]}></CheckboxGroup> */}
            {/* <AppButton htmlType="submit" uxType="secondary" className="button">
                Only total
            </AppButton> */}
            {/* <AppButton
                htmlType="submit"
                uxType="secondary"
                className="button"
                onClick={() => onExclude(10)}
            >
                Exlude below 10%
            </AppButton>
            <AppButton
                htmlType="submit"
                uxType="secondary"
                className="button"
                onClick={() => onExclude(50)}
            >
                Exlude below 50%
            </AppButton> */}
            {/* <AppButton htmlType="submit" uxType="secondary" className="button">
                Add new rule
            </AppButton> */}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_ChartActions.propTypes = {
    className: PropTypes.string.isRequired,
    onExclude: PropTypes.func.isRequired,
    exportUrl: PropTypes.func,
}

_ChartActions.defaultProps = {}

// ----------------------------------------------------------------------------

const ChartActions = styled(_ChartActions)`
    & {
        align-self: flex-start;
        min-height: 100vh;
        height: 100%;
        width: 300px;
        border-left: 1px solid ${({ theme }) => theme.colors.dark200};
        padding-top: ${({ theme }) => theme.spacing.m}px;
        padding-left: ${({ theme }) => theme.spacing.s}px;

        .heading {
            margin: 0;
            padding: 0;
            font-weight: 600;
            font-size: 14px;
            margin-top: 20px;
        }

        .input {
            margin-top: 10px;
        }

        .button-send {
            margin-top: 10px;
            margin-bottom: 30px;
            width: 100%;
        }

        .button {
            width: 100%;
            margin-top: 10px;
        }
    }
`
// ----------------------------------------------------------------------------

export default ChartActions

// ----------------------------------------------------------------------------

const Container = styled.div`
    height: ${(props) => `${props.height}px`};
    overflow: hidden;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: all 200ms ease;
    margin-top: 30px;
    .card {
        padding: 20px;
        background: #ffffff;

        overflow: hidden;
        box-sizing: border-box;

        .field {
            margin-bottom: 10px;
        }
    }
`

/* eslint-disable react/prop-types */
function CreateNote({ visible, onConfirm }) {
    const innerRef = useRef(null)
    const [height, setHeight] = useState(0)
    const [note, setNote] = useState("")

    useLayoutEffect(() => {
        setHeight(innerRef.current.clientHeight)
    }, [])

    function handleConfirm() {
        onConfirm && onConfirm(note)
    }

    function updateNote(field) {
        return (value) => setNote({ ...note, [field]: value })
    }

    return (
        <Container height={visible ? height : 0}>
            <div ref={innerRef} className="card">
                <AppTextArea
                    className="field"
                    value={note?.body}
                    onChange={updateNote("body")}
                    label="Write note"
                />
                <AppButton
                    htmlType="submit"
                    uxType="primary"
                    className="button-send"
                    onClick={handleConfirm}
                >
                    Salva
                </AppButton>
            </div>
        </Container>
    )
}
