import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Menu from "./Menu"

// ----------------------------------------------------------------------------

function _Layout(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, children } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <Menu className="menu"></Menu>
            <div className="content-wrapper">
                <main>{children}</main>

                <footer>v1.0</footer>
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Layout.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.object,
}

_Layout.defaultProps = {}

// ----------------------------------------------------------------------------

const Layout = styled(_Layout)`
    & {
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        .content-wrapper {
            flex: 1;
            max-height: 100vh;
            display: flex;
            flex-direction: column;
            main {
                flex: 1;
                width: 100%;

                overflow-x: hidden;
                overflow-y: auto;
            }

            footer {
                width: 100%;
                text-align: center;
                font-size: 11px;
                opacity: 0.5;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Layout
