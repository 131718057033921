import "dayjs/locale/it"

import dayjs from "dayjs"
/* global process */
import React from "react"
import { Route, HashRouter as Router, Switch } from "react-router-dom"
import styled from "styled-components"

import RoutedApp from "./RoutedApp"
import ThemeProvider from "./ThemeProvider"
import { AppStateStore } from "./_shared/context/AppStateStore"

// ----------------------------------------------------------------------------
//dayjs config

dayjs.locale("it")

// ----------------------------------------------------------------------------

const App = (props) => {
    const initialState = {
        user: null,
        colors: "dark",
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case "SIGN_IN_FULFILLED": {
                return {
                    ...state,
                    token: action.payload.token,
                    user: action.payload.user,
                }
            }

            case "SIGN_OUT": {
                return {
                    ...state,
                    user: null,
                    token: null,
                }
            }

            default: {
                return {
                    ...state,
                }
            }
        }
    }

    return (
        <AppStateStore
            initialState={initialState}
            reducer={reducer}
            storeKey="reef"
        >
            <ThemeProvider>
                <Router
                    className={props.className}
                    basename={process.env.PUBLIC_URL}
                >
                    <Switch>
                        <Route path="/" component={RoutedApp} />
                    </Switch>
                </Router>
            </ThemeProvider>
        </AppStateStore>
    )
}

// ----------------------------------------------------------------------------
// Styled wrapper
// ----------------------------------------------------------------------------

const StyledApp = styled(App)`
    & {
        * {
            color: ${(props) => props?.theme?.colors?.textPrimary};
        }
    }
`

export default StyledApp
