import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import FloatingCard from "../../FloatingCard"
import Checkbox from "../Checkbox"

// ----------------------------------------------------------------------------

function _FloatingCheckbox(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, checked, label, onChange, ...restProps } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <FloatingCard className={`${className}`}>
            <Checkbox
                checked={checked}
                onChange={onChange}
                label={label}
                className="floating-checkbox-inner"
                {...restProps}
            />
        </FloatingCard>
    )
}

// ----------------------------------------------------------------------------
// Component Proptypes
// ----------------------------------------------------------------------------

_FloatingCheckbox.propTypes = {
    className: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
    activeColor: PropTypes.string,
    inactiveColor: PropTypes.string,
}

_FloatingCheckbox.defaultProps = {}

// ----------------------------------------------------------------------------

const FloatingCheckbox = styled(_FloatingCheckbox)`
    & {
        padding: 10px 17px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 8px;
        transition: all 300ms ease;
        background-color: ${({ theme, checked }) =>
            checked ? theme.colors.primary20 : theme.colors.light};
    }
`

// ----------------------------------------------------------------------------

export default FloatingCheckbox
