import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _FloatingCard(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return <div className={`${className}`}>{props.children}</div>
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_FloatingCard.propTypes = {
    className: PropTypes.string.isRequired,
}

_FloatingCard.defaultProps = {}

// ----------------------------------------------------------------------------

const FloatingCard = styled(_FloatingCard)`
    & {
        box-shadow: 4px 0px 50px 0px rgba(0, 0, 0, 0.06);
        background-color: white;
        border-radius: 20px;
    }
`
// ----------------------------------------------------------------------------

export default FloatingCard
