import PropTypes from "prop-types"
import React from "react"
import { Bar } from "react-chartjs-2"
import { BsChevronDown } from "react-icons/bs"
import styled from "styled-components"

import CustomSelect from "../../../../_shared/components/FormItems/CustomSelect"

// ----------------------------------------------------------------------------

function _Chart(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        data,
        title,
        filterParams = { filters: [], active: null, onChange: () => {} },
    } = props

    const { filters, active, onChange } = filterParams

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    const getAxisOptions = ({ title, font }) => ({
        grid: {
            lineWidth: 0,
            drawBorder: false,
        },

        ticks: {
            font: {
                size: 12,
                weight: 600,
            },
        },

        title: {
            display: !!title,
            text: title,
            font: font ?? {
                size: 16,
                weight: 700,
            },
        },
    })

    function renderFilterSelector() {
        return (
            <div className="selector-wrapper">
                <CustomSelect
                    renderSelector={({ label }) => (
                        <div className="filter-select">
                            <span className="select-value">{label}</span>
                            <div className="select-icon">
                                <BsChevronDown />
                            </div>
                        </div>
                    )}
                    options={filters}
                    value={active}
                    onChange={onChange}
                />
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <h3 className="chart-title">{title}</h3>
            {/* {renderFilterSelector()} */}
            <Bar
                data={data}
                options={{
                    datasets: {
                        bar: {
                            barPercentage: 1,
                        },
                    },
                    plugins: {
                        legend: {
                            position: "top",
                        },
                    },
                    scales: {
                        x: getAxisOptions({
                            // title: "Refresh time: 0,5 sec",
                            font: {
                                size: 14,
                                weight: 400,
                            },
                        }),
                        y: getAxisOptions({ title: "kgCO2eq" }),
                    },
                }}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Chart.propTypes = {
    className: PropTypes.string.isRequired,
    data: PropTypes.object,
    options: PropTypes.object,
    title: PropTypes.string,
}

_Chart.defaultProps = {}

// ----------------------------------------------------------------------------

const Chart = styled(_Chart)`
    & {
        flex: 1;
        padding: ${({ theme }) => theme.spacing.s}px;
        box-shadow: 0px 0px 12px ${({ theme }) => theme.colors.shadow};

        .chart-title {
            text-align: center;
            display: block;
            font-size: 16px;
            margin: 0;
            padding: 0;
            padding-bottom: ${({ theme }) => theme.spacing.s}px;
            font-size: 16px;
            font-weight: 600;
        }
        .selector-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: ${({ theme }) => theme.spacing.s}px;

            .filter-select {
                width: 120px;
                height: 40px;
                border-radius: 6px;
                background-color: ${({ theme }) => theme.colors.dark100};
                display: flex;
                align-self: center;
                justify-content: center;
                padding: 0px 10px;

                .select-value {
                    display: block;
                    min-width: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-weight: 500;
                }

                .select-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: ${({ theme }) => theme.spacing.xs}px;
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Chart
