import PropTypes from "prop-types"
import React, { useState } from "react"
import styled, { css } from "styled-components"

//------------------------------------------------------------------------------

function _AppInput(props) {
    const {
        className,
        label,
        hiddenText,
        value,
        validationMessage,
        disabled,
        required,
    } = props

    const [focused, setIsFocused] = useState("")

    // -------------------------------------

    function onChange(event) {
        const { onChange } = props

        onChange(event.target.value)
    }

    function onBlur(event) {
        const { onBlur } = props

        setIsFocused(false)
        onBlur(event)
    }

    function handleFocus() {
        setIsFocused(true)
    }

    function getStateClass() {
        let cssClass = ""
        if (focused) {
            cssClass = "focused"
        } else {
            cssClass = value ? "valid" : ""
        }

        return cssClass
    }

    // -------------------------------------

    return (
        <div className={`${className} ${getStateClass()} app-input`}>
            <input
                type={(hiddenText && "password") || "text"}
                value={value || ""}
                className="tm4-input"
                onChange={onChange}
                onBlur={onBlur}
                onFocus={handleFocus}
                required={required}
                disabled={disabled}
                autoComplete="off"
            />
            <label className="input-label">{label}</label>

            {validationMessage && (
                <span className="validation-error-message">
                    {validationMessage}
                </span>
            )}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppInput.propTypes = {
    className: PropTypes.string.isRequired,
    label: PropTypes.string,
    hiddenText: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    validationMessage: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
}

_AppInput.defaultProps = {
    onChange: () => {},
    onBlur: () => {},
    validationMessage: null,
    disabled: false,
}

// ----------------------------------------------------------------------------

const AppInput = styled(_AppInput)`
    & {
        position: relative;

        display: flex;
        flex-direction: column;
        border-radius: 6px;
        min-height: 48px;
        height: 48px;
        max-height: 48px;
        font-size: 14px;
        max-width: 300px;
        width: 100%;
        background-color: transparent;

        overflow: hidden;

        ${(props) =>
            props.disabled &&
            css`
                cursor: not-allowed;
            `}

        .tm4-input {
            flex: 1;
            z-index: 2;
            border: 1px solid ${(props) => props.theme.colors.primary};
            border-radius: 6px;
            padding: 0 10px 0 10px;
            height: 48px;
            max-height: 48px;
            background-color: transparent;
            font-size: 14px;
            color: ${(props) => props.theme.colors.dark400};

            :focus {
                outline: none;
                border-color: ${(props) =>
                    props.theme.colors.primary} !important;
            }

            :focus,
            :valid {
                padding-top: 8px;
            }
        }

        .input-label {
            position: absolute;
            top: 50%;

            transform: translateY(-50%);

            left: 10px;
            font-size: 15px;
            color: ${(props) => props.theme.colors.dark300};
            z-index: 1;
            transition: 0.3s ease all;
            -moz-transition: 0.3s ease all;
            -webkit-transition: 0.3s ease all;

            user-select: none;

            ${(props) =>
                props.disabled &&
                css`
                    z-index: 1;
                    color: #d6d6d6;
                    cursor: not-allowed;
                `}
        }

        &.focused,
        &.valid {
            .tm4-input {
                border-color: ${(props) =>
                    props.theme.colors.primary} !important;
            }
            .input-label {
                color: ${({ theme }) => theme.colors.primary};
            }
        }

        .validation-error-message {
            position: absolute;
            top: 50%;
            left: 102%;
            width: 50%;
            transform: translateY(-50%);
            font-size: 10px;
            font-weight: 600;
            color: rgb(255, 59, 48);
        }

        /* MOBILE */
        @media (max-width: 576px) {
            .validation-error-message {
                position: absolute;
                top: 101%;
                left: 10px;
                width: 100%;
                transform: unset;
                line-height: 1;
            }
        }
    }

    &.focused {
        .input-label {
            position: absolute;
            top: 8.5px;
            font-size: 10px;
            font-weight: 600;
            color: ${(props) => props.theme.colors.primaryColor};
        }
    }

    &.valid {
        .input-label {
            position: absolute;
            top: 8.5px;
            font-size: 10px;
            font-weight: 600;
        }
    }
`
// ----------------------------------------------------------------------------

export default AppInput
