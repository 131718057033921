import { useEffect, useReducer, useRef } from "react"

import * as api from "../../../api"

//------------------------------------------------------------------------------

export default function useRestResource(resourceUrl, token, options = {}) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { polling = null, defaultFilters = {}, defaultSort = {} } = options

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const initialState = {
        resource: null,
        filters: defaultFilters,
        isLoading: false,
        error: null,
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const pollingRef = useRef()

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        fetchResource()

        if (polling?.interval > 0) {
            // clear interval if exists
            pollingRef.current && clearInterval(pollingRef.current)

            pollingRef.current = setInterval(() => {
                fetchResource(polling?.getFilters?.())
            }, polling?.interval)
        }

        return function cleanup() {
            if (polling?.interval > 0) {
                clearInterval(pollingRef.current)
            }
        }

        //eslint-disable-next-line
    }, [
        resourceUrl,
        state.filters,
        state.sort,
        state?.pagination?.pageSize,
        state?.pagination?.pageNumber,
        polling?.interval,
    ])

    // -------------------------------------
    // local functions
    // -------------------------------------

    function reducer(state, action) {
        switch (action.type) {
            case "FETCH_PENDING":
                return {
                    ...state,
                    isLoading: true,
                }

            case "FETCH_FULFILLED": {
                return {
                    ...state,
                    isLoading: false,
                    resource: action.payload,
                }
            }
            case "FETCH_REJECTED":
                return {
                    ...state,
                    error: action.payload,
                }

            case "FILTERS_CHANGE": {
                return {
                    ...state,
                    filters: action.payload,
                }
            }
            default:
                throw new Error(
                    "Error in useRestResource: Unable to recognize action type"
                )
        }
    }

    function urlWithQueryParams(url, params) {
        const urlParams = Object.entries(params)
            .map(
                ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
            )
            .join("&")
        return `${url}?${urlParams}`
    }

    function handleFiltersChange(filters) {
        dispatch({
            type: "FILTERS_CHANGE",
            payload: filters,
        })
    }

    async function fetchResource(externalFilters = null) {
        try {
            let url = `${resourceUrl}`

            if (state?.filters || externalFilters) {
                const finalFilters = {
                    ...(state?.filters ?? {}),
                    ...(externalFilters ?? {}),
                }

                url = urlWithQueryParams(url, finalFilters)
            }

            dispatch({
                type: "FETCH_PENDING",
            })
            const newResource = await api.getResource(url, token)

            dispatch({
                type: "FETCH_FULFILLED",
                payload: newResource,
            })
        } catch (error) {
            dispatch({
                type: "FETCH_REJECTED",
                payload: error,
            })
        }
    }

    // -------------------------------------
    // returned values
    // -------------------------------------

    const { resource, isLoading, error } = state

    return [
        resource,
        isLoading,
        error,
        {
            fetch: fetchResource,
            onFiltersChange: handleFiltersChange,
        },
    ]
}
