import "../../index.css"

import PropTypes from "prop-types"
import React from "react"
import {
    ThemeProvider as StyledComponentsThemeProvider,
    createGlobalStyle,
} from "styled-components"

// ----------------------------------------------------------------------------

export default function ThemeProvider(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { children } = props

    const theme = {
        colors: {
            primary: "#7ac6d0",
            primaryShadow: "rgba(122, 198, 208, 0.4)",
            secondary: "#f9d447",
            error: "#DC3545",
            error200: "#FED8D9",
            error400: "#FED8D9",
            success: "#38BF86",
            // menuActive: "#fdd457",
            menuActive: "rgba(255,255,255,0.5)",

            dark: "#000000",

            dark300: "#CECECE",
            dark200: "#d9d9d9",
            dark100: "#f5f5f5",

            light: "#FFFFFF",

            shadow: "rgba(0,0,0,0.2)",
        },
        spacing: {
            xs: 5,
            s: 20,
            m: 40,
            l: 60,
        },
    }

    /* eslint-disable react/prop-types  */
    const GlobalStyle = createGlobalStyle`
         .center {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    `
    /* eslint-enable react/prop-types  */

    return (
        <StyledComponentsThemeProvider theme={theme}>
            <GlobalStyle />
            {children}
        </StyledComponentsThemeProvider>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ThemeProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
}

ThemeProvider.defaultProps = {}
