import { message } from "antd"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import AppButton from "../../_shared/components/AppButton"
import AppInput from "../../_shared/components/FormItems/AppInput"
import { useStateValue } from "../../_shared/context/AppStateStore"
import * as api from "../../api"
import CommandLine from "./CommandLine"

// ----------------------------------------------------------------------------

function _AuthPage(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [, dispatch] = useStateValue()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleSignIn(event) {
        event.preventDefault()

        api.signIn(email, password)
            .then((res) => {
                dispatch({
                    type: "SIGN_IN_FULFILLED",
                    payload: res,
                })
            })
            .catch((error) => {
                if (error && error.statusCode === 401) {
                    message.error("Le credenziali non sono corrette.")
                } else {
                    const defaultMessage =
                        "Non è stato possibile completare il login. Riprovare più tardi."

                    const messageText =
                        (error && error.serverMessage) || defaultMessage

                    message.error(messageText)
                }
            })
    }

    function renderLeft() {
        return (
            <div className="left">
                <form onSubmit={handleSignIn} className="auth-form">
                    <h1>Reef</h1>
                    <AppInput
                        value={email}
                        onChange={setEmail}
                        label="E-Mail"
                        className="input"
                    />
                    <AppInput
                        value={password}
                        onChange={setPassword}
                        label="Password"
                        hiddenText
                        className="input"
                    />
                    <AppButton
                        htmlType="submit"
                        uxType="primsary"
                        className="button"
                    >
                        Login
                    </AppButton>
                </form>
            </div>
        )
    }

    function renderRight() {
        return (
            <div className="right">
                <img
                    src={
                        require("../../_shared/assets/images/intro-image.png")
                            .default
                    }
                    width="70%"
                    style={{ marginBottom: 50 }}
                />
                <span className="subtitle">
                    Real Time Plant We monitor the energy consumption of a plant
                </span>
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            {renderLeft()}
            {renderRight()}
            <CommandLine />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AuthPage.propTypes = {
    className: PropTypes.string.isRequired,
}

_AuthPage.defaultProps = {}

// ----------------------------------------------------------------------------

const AuthPage = styled(_AuthPage)`
    & {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;

        .left {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            form {
                width: 300px;
            }
            h1 {
                font-size: 35px;
                /* text-align: center; */
                width: 100%;
            }

            .input {
                margin-bottom: 10px;
                width: 100%;
            }
            .button {
                margin-top: 20px;
                width: 100%;
            }
        }

        .right {
            flex: 1;
            display: flex;
            flex-direction: column;

            background-color: ${({ theme }) => theme.colors.secondary};
            justify-content: center;
            align-items: center;

            .subtitle {
                margin-top: -50px;
                color: #fff;
                font-size: 24px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AuthPage
