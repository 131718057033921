import { DatePicker } from "antd"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _AppDatePicker(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, value, onChange, label } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [focused, setIsFocused] = useState("")
    const pickerRef = useRef()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleFocus() {
        setIsFocused(true)
    }

    function handleBlur() {
        setIsFocused(false)
    }

    function getStateClass() {
        let cssClass = ""
        if (focused) {
            cssClass = "focused"
        } else {
            cssClass = value ? "valid" : ""
        }

        return cssClass
    }

    function handleChange(e) {
        onChange && onChange(e ? e.format("YYYY-MM-DD") : null)
    }

    function handleLabelClick() {
        pickerRef?.current?.focus()
        console.log(pickerRef?.current)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className} ${getStateClass()}`}>
            <label className="input-label">{label}</label>
            <DatePicker
                ref={pickerRef}
                className="nograv-picker"
                value={moment(value)}
                onChange={handleChange}
                placeholder=""
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppDatePicker.propTypes = {
    className: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
}

_AppDatePicker.defaultProps = {}

// ----------------------------------------------------------------------------

const AppDatePicker = styled(_AppDatePicker)`
    & {
        position: relative;
        .ant-picker {
            width: 100%;
            max-width: 300px;
            height: 48px;
            border-radius: 6px;
            border: 1px solid ${({ theme }) => theme.colors.primary};
            box-shadow: none !important;
            padding: 0 10px 0 10px;
            font-size: 14px;

            .ant-picker-input {
                padding-top: 8px;
                input {
                }
                .ant-picker-clear {
                    padding-top: 8px;
                }
            }

            &.ant-picker-focused {
            }
        }
        .input-label {
            position: absolute;
            left: 10px;
            top: calc(50% + 4px);
            transform: translateY(-50%);
            transition: all 200ms ease;
            color: ${({ theme }) => theme.colors.dark300};
            z-index: 1;
            pointer-events: none;
        }
        &.valid,
        &.focused {
            .ant-picker {
                border: 1px solid ${({ theme }) => theme.colors.primary};
                .ant-picker-input {
                    input {
                    }
                }
            }
            .input-label {
                top: 10px;
                color: ${({ theme }) => theme.colors.primary};
                font-weight: 700;
                font-size: 10px;

                /* transform: translateY(0%); */
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AppDatePicker
