import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Checkbox from "../Checkbox"
import FloatingCheckbox from "../FloatingCheckbox"

// ----------------------------------------------------------------------------

function _CheckboxGroup(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, value, label, floating, onChange, options } = props
    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleChange(boxValue) {
        return () => {
            const checked = value?.some((v) => boxValue === v)
            let newValue
            if (checked) {
                newValue = value?.filter((v) => v !== boxValue)
            } else {
                newValue = [...(value ?? []), boxValue]
            }
            onChange && onChange(newValue)
        }
    }

    function renderBoxes() {
        return (
            <div className="boxes">
                {options?.map((o) => {
                    const option =
                        typeof o === "string" ? { label: o, value: o } : o
                    const boxProps = {
                        checked: value?.some((v) => option.value === v),
                        label: option.label,
                        key: option.value,
                        onChange: handleChange(option.value),
                    }
                    return floating ? (
                        <FloatingCheckbox
                            {...boxProps}
                            className="checkbox-group-member"
                        />
                    ) : (
                        <Checkbox
                            {...boxProps}
                            className="checkbox-group-member checkbox-plain"
                        />
                    )
                })}
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            {label && <span className="checkbox-group-label">{label}</span>}
            {renderBoxes()}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_CheckboxGroup.propTypes = {
    className: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.array,
    floating: PropTypes.bool,
}

_CheckboxGroup.defaultProps = {}

// ----------------------------------------------------------------------------

const CheckboxGroup = styled(_CheckboxGroup)`
    & {
        .checkbox-group-label {
            display: block;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        .checkbox-group-member {
            margin-bottom: 10px;
        }
    }
`
// ----------------------------------------------------------------------------

export default CheckboxGroup
