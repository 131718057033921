import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import {
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    PointElement,
    RadialLinearScale,
    Tooltip,
} from "chart.js"
import PropTypes from "prop-types"
import { Radar } from "react-chartjs-2"
import { BsChevronDown } from "react-icons/bs"

import CustomSelect from "../../../../_shared/components/FormItems/CustomSelect"
import { DatePicker, Space } from "antd"
const { RangePicker } = DatePicker

// ----------------------------------------------------------------------------

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
)

// ----------------------------------------------------------------------------

function _Chart(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        data: chartData,
        filterParams = { filters: [], active: null, onChange: () => {} },
        showDates,
        setDatas,
    } = props

    const { filters, active, onChange } = filterParams

    console.log("DATA: ", chartData)

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const chartRef = useRef(null)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        chartRef?.current?.update?.()
    }, [chartData])

    function renderFilterSelector() {
        return (
            <CustomSelect
                renderSelector={({ label }) => (
                    <div className="filter-select">
                        <span className="select-value">{label}</span>
                        <div className="select-icon">
                            <BsChevronDown />
                        </div>
                    </div>
                )}
                options={filters}
                value={active}
                onChange={onChange}
            />
        )
    }

    function renderDates() {
        return (
            <div>
                <RangePicker
                    style={{ height: "40px" }}
                    onChange={(value, dateStrings) => setDatas(dateStrings)}
                />
            </div>
        )
    }

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <div className="inner">
                <div className="selector-wrapper">
                    {showDates && renderDates()}
                    {renderFilterSelector()}
                </div>

                <Radar
                    data={{
                        labels: [
                            "Impatto energetico",
                            "Impatto H2O",
                            "Impatto polveri",
                            "Impatto aria",
                        ],
                        datasets: [
                            {
                                data: chartData,
                                backgroundColor: "#FBE2E6",
                                borderColor: "#FF657E",
                                borderWidth: 1,
                            },
                        ],
                    }}
                    className="radar"
                    options={{
                        plugins: {
                            legend: { display: false },
                        },
                        scales: {
                            r: {
                                ticks: {
                                    display: false,
                                    maxTicksLimit: 3,
                                },
                                pointLabels: {
                                    font: {
                                        size: 14,
                                        family: "DM Sans",
                                    },
                                },
                            },
                        },
                    }}
                />
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Chart.propTypes = {
    className: PropTypes.string.isRequired,
}

_Chart.defaultProps = {}

// ----------------------------------------------------------------------------

const Chart = styled(_Chart)`
    & {
        width: 100%;
        padding: ${({ theme }) => theme.spacing.s}px;
        box-shadow: 0px 0px 12px ${({ theme }) => theme.colors.shadow};
        display: flex;
        align-items: center;
        justify-content: center;

        .inner {
            width: 50%;
            .radar {
            }
        }
        .selector-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            margin-bottom: ${({ theme }) => theme.spacing.s}px;

            .filter-select {
                width: 120px;
                height: 40px;
                border-radius: 6px;
                background-color: ${({ theme }) => theme.colors.dark100};
                display: flex;
                align-self: center;
                justify-content: center;
                padding: 0px 10px;

                .select-value {
                    display: block;
                    min-width: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-weight: 500;
                }

                .select-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: ${({ theme }) => theme.spacing.xs}px;
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Chart
